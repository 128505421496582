import React from "react";
import {MetaData as MetaInterface} from "../apiStrapi/models/contentType/home";
import Head from "next/head";
import {PopulatedImageMetaData} from "../apiStrapi/models/contentType/seasonalPromotion";

interface MetaInterFace {
  data: MetaInterface | PopulatedImageMetaData | null,
  imageUrl?: string | null
}

export default function MetaDescription(data: MetaInterFace) {
  const metaData = data?.data || null;
  const originalTitle = "Cariber | ความสำเร็จที่เรียนรู้ได้";
  const originalUrl = 'https://home.cariber.co/';
  const originalDesc = "ใครว่าประสบการณ์เป็นสิ่งที่สอนกันไม่ได้? ที่ Cariber ผู้เชี่ยวชาญจากหลายแวดวงจะมาถ่ายทอดความรู้พร้อมถอดบทเรียนจากประสบการณ์ตรงให้ทุกคนฟัง ว่ากว่าจะมายืนบนจุดที่เรียกว่าความสำเร็จ พวกเขาต้องผ่านบททดสอบอะไรมาบ้าง แล้วประสบการณ์ที่ผ่านมา สอนอะไรให้กับพวกเขา";
  const originalImage = 'https://home.cariber.co/login/image-title.jpg';

  return <Head>
    <link rel="icon" href="/title-icon.png" type="image/x-icon"></link>
    <title>{ metaData?.title || originalTitle }</title>
    <meta name="description" content={ metaData?.description || originalDesc }/>

    <meta property="og:url" content={ metaData?.url || originalUrl } />
    <meta property="og:type" content="website"/>
    <meta property="og:title" content={ metaData?.title || originalTitle } />
    <meta property="og:description" content={ metaData?.description || originalDesc } />
    <meta property="og:image" content={ data?.imageUrl || metaData?.preview_image?.url || originalImage} />
    <meta property="og:image:url" content={ data?.imageUrl || metaData?.preview_image?.url || originalImage} />
    <meta property="og:image:secure_url" content={ data?.imageUrl || metaData?.preview_image?.url || originalImage} />

    <meta name="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content={ metaData?.url || originalUrl } />
    <meta name="twitter:title" content= { metaData?.title || originalTitle } />
    <meta name="twitter:description" content={ metaData?.description || originalDesc } />
    <meta property="twitter:image" content={ data?.imageUrl || metaData?.preview_image?.url || originalImage} />
  </Head>
}
