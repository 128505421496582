interface Instructor {
  id: number;
  name: string;
  lms_id: number;
  idiom: string;
  profile_image: string;
  createDate: string;
  updateDate: string;
  deletedAt: string;
}

export enum ShowingType {
  episode = 'episode',
  courseEvaluation = 'evaluation',
  certificate = 'certificate',
  assessment = 'assessment',
}

export interface CourseLMS {
  id: number;
  speaker_name: string;
  course_name: string;
  description: string;
  total_hours: string;
  total_lessons: string;
  order_link: string;
  header: string;
  asset_download: string;
  thumbnail_image: string;
  lms_id: number;
  createDate: string;
  updateDate: string;
  sequence: number;
  deletedAt?: any;
  episodes_list: EpisodesAndEvaluations[];
  episode: Episodes[];
  instructor: Instructor;
  statusCode: number;
  slug: string;
}

export interface Episodes {
  id: number;
  episode_number: number;
  episode_name: string;
  description: string;
  link_video: string;
  thumbnail_image: string;
  lms_id: number;
  is_free_trial: boolean;
  createDate: string;
  updateDate: string;
  deletedAt?: string;
  type: ShowingType;
  duration?: number;
  showDetails?: boolean;
  assessment: AssessmentItem;
  isAssessment?: boolean;
}

export interface Assessment {
  id: number;
  title: string;
  sequence: null;
  answers: string[];
  createDate: string;
  updateDate: string;
  question_type: 'text';
  answer_explanation: null;
  deletedAt?: string | null;
  correct_answers: string[];
  assessment_response: string[];
}
export interface AssessmentItem {
  assessment_question: Assessment[];
  answer_explanation: string;
  answers: string[];
  correct_answers: string[];
  createDate: string;
  deletedAt: string;
  id: number;
  question_type: string;
  sequence: number;
  length: number;
  description: string;
  is_completion: false;
  passed_percentage: number;
  title: string;
  updateDate: string;
}

export interface QuizChoice {
  id: number;
  choice_number: number;
  choice_description: string;
  createDate: string;
  updateDate: string;
  deletedAt?: string;
  duration?: number;
  showDetails?: boolean;
}

export class Evaluation {
  id: number;
  name: string;
  thumbnail_image: string;
  type: ShowingType;
  episode_number: number;
  duration?: number;
  showDetails?: boolean;
  assessment: AssessmentItem | undefined;
  isAssessment?: boolean;
  episode_name?: string;
  constructor() {
    this.id = 0;
    this.name = 'Post Course Evaluation';
    this.thumbnail_image =
      'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production//site/163642/products/kT3DcuQ96w0cDdw8xHAy_XZhAghO1QNa8YL1cQtDe_EqIBRqYhQjClS06NZN4c_SeaTalk_IMG_1622212691.jpg';
    this.type = ShowingType.courseEvaluation;
    this.episode_number = 0;
  }
}

export class Certificate {
  id: number;
  name: string;
  thumbnail_image: string;
  type: ShowingType;
  episode_number: number;
  duration?: number;
  assessment: any;
  isAssessment?: boolean;
  constructor() {
    this.id = 0;
    this.name = 'Download Certificate';
    this.thumbnail_image = '';
    this.type = ShowingType.certificate;
    this.episode_number = 0;
  }
}

export interface OnGoingEpisodes {
  episodeID: number;
  lastSecond: string;
}

export interface CourseAndEpIds {
  courseId: number,
  episodeId: number,
}

export type EpisodesAndEvaluations = Episodes | Evaluation;

export interface NextInstructorFormProps {
  email: string;
  nextInstructorName: string;
}
