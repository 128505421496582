export function detectMobileIOS() {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function detectSafari() {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function detectAndroid() {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return /(android)/i.test(navigator.userAgent);
}
