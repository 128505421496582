import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import screenfull from 'screenfull';
import Img from './image';
import { getTrackBackground, Range } from 'react-range';
import { Direction, IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types';
import { VideoComponent, VideoPlayingState } from '../apiStrapi/models/component/video';
import moment from 'moment';
import {Episodes, EpisodesAndEvaluations, Evaluation, ShowingType} from '../apiNest/models/content/courseLms';
import Countdown from './countdown';
import { getSignedToken } from "../apiNest/NestApiService";
import { saveFirstWatched } from "../apiNest/NestApiService";
import { detectAndroid, detectMobileIOS, detectSafari } from '../functions/checkIOS';

interface VideoContinue {
  episodeOrQuiz: EpisodesAndEvaluations,
  callBackContinue: () => void,
}

type ReactVideoPlayerState = {
  url: string;
  pip: boolean,
  playing: boolean;
  controls: boolean;
  light: boolean | string;
  volume: number;
  muted: boolean;
  played: number;
  playedSeconds: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking: boolean;
  resolutions: any;
  currentResolution: number;
  fullscreen: boolean;
  needLoadedSeconds: number;
}

enum Directions {
  Horizontal,
  Vertical
}

function VideoPlayer({
  props,
  videoContinue }: {
    props: VideoComponent,
    imageStrapi?: boolean,
    videoContinue?: VideoContinue
  }) {

  const [playbackOptions, setPlaybackOptions] = useState<number[]>([
    0.25,
    0.5,
    0.75,
    1,
    1.25,
    1.5,
    1.75,
    2
  ]);
  const [continueVisible, setContinueVisible] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const [watchedPrgressCount, setWatchedProgressCount] = useState(0);
  const [showSubtitle, setShowSubtitle] = useState<boolean>(false);
  const [hasSubtitle, setHasSubtitle] = useState<boolean>(false);
  const player = useRef<ReactPlayer>(null);
  const playerContainerRef = useRef(null);
  const [controllerVisible, setControllerVisible] = useState(false);
  const [resolutionsVisible, setResolutionsVisible] = useState(false);
  const [playbackVisible, setPlaybackVisible] = useState(false);
  const [volumeVisible, setVolumeVisible] = useState(false);
  const [isProgressed, setIsProgressed] = useState(false);
  const [onStarted, setOnStarted] = useState(false);
  const [playingStateBeforeStopForLoading, setPlayingStateBeforeStopForLoading] = useState<boolean | undefined>(undefined);
  const [videoState, setVideoState] = useState<ReactVideoPlayerState>({
    url: `https://videodelivery.net/${props.video_id}/manifest/video.m3u8`,
    pip: false,
    playing: props.autoplay as boolean,
    controls: false,
    light: false,
    volume: props.muted ? 0 : 1,
    muted: props.muted as boolean,
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: props.loop || false,
    seeking: false,
    resolutions: [],
    currentResolution: -1,
    fullscreen: false,
    needLoadedSeconds: 10,
  });

  const handleReady = () => {
    if (props.lastSecond && !isProgressed) {
      setTimeout(() => {
        player.current?.seekTo(+props.lastSecond!, 'seconds');

        const videoElement = document.querySelector('video');
        videoElement && (videoElement.currentTime = +props.lastSecond!);
      }, 100);
    }
    const hlsPlayer = player.current?.getInternalPlayer('hls');
    if (hlsPlayer) {
      setVideoState({ ...videoState, resolutions: hlsPlayer.levels });
      setTimeout(() => {
        setHasSubtitle(Boolean(hlsPlayer.subtitleTracks?.length > 0));
        hlsPlayer.subtitleTracks?.length > 0 && toggleSubtitle();
      }, 10);
    };
  }

  function toggleSubtitle() {
    const hlsPlayer = player.current?.getInternalPlayer('hls');
    if (hlsPlayer) {
      hlsPlayer.subtitleTrack = hlsPlayer.subtitleTrack === 0 ? -1 : 0;
      setShowSubtitle(hlsPlayer.subtitleTrack === 0);
    }
  }

  const setVideoResolution = (value: number) => {
    const hlsPlayer = player.current?.getInternalPlayer('hls');
    if (!hlsPlayer) return;
    setVideoState({ ...videoState, currentResolution: value });
    hlsPlayer.nextLevel = value;
  }

  const load = (url: string) => {
    setVideoState({
      ...videoState,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  const handlePlayPause = () => {
    setVideoState({ ...videoState, playing: !videoState?.playing });
    clearPlayingStateBeforeStopForLoading();
  }

  const handleToggleLight = () => {
    setVideoState({ ...videoState, light: !videoState.light });
  }

  const handleToggleLoop = () => {
    setVideoState({ ...videoState, loop: !videoState.loop });
  }

  const handleVolumeChange = (value: number) => {
    setVideoState({
      ...videoState,
      volume: value,
      muted: !Boolean(value)
    });
  }

  const handleToggleMuted = () => {
    setVideoState({
      ...videoState,
      muted: !videoState.muted,
      volume: !videoState.muted ? 0 : 1,
    });
  }

  const handleSetPlaybackRate = (speed: number) => {
    setVideoState({ ...videoState, playbackRate: speed })
  }

  const handleTogglePIP = () => {
    setVideoState({ ...videoState, pip: !videoState.pip })
  }

  const handlePlay = () => {
    sendDataToSegment('Video Player Resumed')
    setOnStarted(true);
    setVideoState({ ...videoState, playing: true })
    clearPlayingStateBeforeStopForLoading();
  }

  const handleEnablePIP = () => {
    setVideoState({ ...videoState, pip: true })
  }

  const handleDisablePIP = () => {
    setVideoState({ ...videoState, pip: false })
  }

  const handlePause = () => {
    sendDataToSegment('Video Player Paused')
    setVideoState({ ...videoState, playing: false })
  }

  const handleSeekChange = (value: number) => {
    setVideoState({ ...videoState, played: value });
    player.current?.seekTo(value);
  }

  const clearPlayingStateBeforeStopForLoading = () => {
    setPlayingStateBeforeStopForLoading(undefined);
  }

  const handleVideoJittery = (state: VideoPlayingState) => {
    if (
      state.loadedSeconds - state.playedSeconds <
      videoState.needLoadedSeconds &&
      playingStateBeforeStopForLoading === undefined &&
      state.loaded < 1
    ) {
      setPlayingStateBeforeStopForLoading(videoState.playing);
      setVideoState({
        ...videoState,
        playing: false,
      });
      console.log('video player: unstable internet')
      return;
    }
    if (state.loaded === 1 && playingStateBeforeStopForLoading) {
      setVideoState({
        ...videoState,
        playing: Boolean(playingStateBeforeStopForLoading),
      });
      clearPlayingStateBeforeStopForLoading();
      return;
    }
    if (
      state.loadedSeconds - state.playedSeconds >
      videoState.needLoadedSeconds &&
      playingStateBeforeStopForLoading
    ) {
      setVideoState({
        ...videoState,
        playing: Boolean(playingStateBeforeStopForLoading),
      });
      setPlayingStateBeforeStopForLoading(undefined);
    }
  };

  const handleProgress = (state: VideoPlayingState) => {
    handleVideoJittery(state);
    if (watchedPrgressCount >= 4) {
      sendDataToSegment('Video Player Watching In Progress')
      setWatchedProgressCount(0);
    } else {
      setWatchedProgressCount(watchedPrgressCount + 1);
    }
    setIsProgressed(true);
    if (!videoState.seeking) {
      setVideoState({ ...videoState, ...state });
    }
    if (progressCount > 3) {
      setControllerVisible(false);
      setProgressCount(0);
    }
    if (controllerVisible) {
      setProgressCount(progressCount + 1);
    }
    localStorage.setItem('episodeCompletionPercentage', (videoState.played*100).toFixed(2));
    localStorage.setItem('lastSecond', videoState.playedSeconds.toFixed(2).toString())
  }

  const handleOnSeek = () => {
    sendDataToSegment('Video Player On Seek')
  }

  const handleEnded = () => {
    props.handleEnded && props.handleEnded();
    sendDataToSegment('Video Player Completed')
    setTimeout(() => {
      setContinueVisible(true);
      setVideoState({ ...videoState, playing: videoState.loop });
    }, 1000);
  }

  const sendDataToSegment = (title: string) => {
    global.analytics.track(title, {
      "episodeID": localStorage.getItem('episodeID'),
      "episodeName": localStorage.getItem('episodeName'),
      "courseID": localStorage.getItem('courseID'),
      "courseName": localStorage.getItem('courseName'),
      "lastSecond": localStorage.getItem('lastSecond'),
      "userData": localStorage.getItem('ajs_user_traits'),
      "episodeCompletionPercentage": localStorage.getItem('episodeCompletionPercentage'),
    })
  }

  const handleContinue = () => {
    if (!continueVisible) return;
    videoContinue?.callBackContinue();
  }

  const handleDuration = (duration: number) => {
    setVideoState({ ...videoState, duration })
  }

  const handleClickFullscreen = () => {
    playerContainerRef.current && screenfull.toggle(playerContainerRef.current);
  }

  const handleVisible = (setState: Dispatch<SetStateAction<boolean>>) => {
    setState(true);
    setProgressCount(0);
  }

  const handleHidden = (setState: Dispatch<SetStateAction<boolean>>) => {
    setState(false)
  }

  const handleSwitchVisible = (visible: boolean, setState: Dispatch<SetStateAction<boolean>>) => {
    setState(!visible)
  }

  const handleStart = () => {
    sendDataToSegment('Video Player Started')
    setOnStarted(true);
    saveFirstWatched().then(() => { });
  }

  const getToken = () => {
    getSignedToken(props.video_id)
      .then(async res => {
        setIsProgressed(false);
        await setVideoState({
          ...videoState,
          url: `https://videodelivery.net/${res.token}/manifest/video.m3u8`,
          playing: props.autoplay as boolean,
          controls: forceNativeController(),
        });
      })
      .catch(() => {
        console.log("video not exist")
      });
  }

  useEffect(() => {
    getToken();
    setContinueVisible(false);
  }, [props.video_id]);

  const forceNativeController: () => boolean = () => {
    if (detectMobileIOS() || detectSafari() || detectAndroid()) {
      return true;
    }
    return false;
  }

  function getMinuteAndSecond(totalSeconds: number): string{
    const minutes = ~~(totalSeconds / 60);
    const seconds = ~~(totalSeconds % 60);
    return (+minutes < 10 ? `0${minutes}` : minutes.toString())
      + ':'
      + (seconds < 10 ? `0${seconds}` : seconds.toString());
  }

  const renderTrack = ({
    iRenderTrackParams,
    value,
    directions }: {
      iRenderTrackParams: IRenderTrackParams,
      value: number,
      directions: Directions
    }) => {
    let directionProps = {
      width: "",
      outerHeight: "",
      innerHeight: "",
      direction: Direction.Right
    }

    switch (directions) {
      case Directions.Horizontal:
        directionProps = {
          width: "100%",
          outerHeight: "auto",
          innerHeight: "6px",
          direction: Direction.Right
        }
        break;
      case Directions.Vertical:
        directionProps = {
          width: "6px",
          outerHeight: "100%",
          innerHeight: "100%",
          direction: Direction.Up
        }
        break;
    }

    return (
      <div
        onMouseDown={iRenderTrackParams.props.onMouseDown}
        onTouchStart={iRenderTrackParams.props.onTouchStart}
        style={{
          ...iRenderTrackParams.props.style,
          height: directionProps.outerHeight,
          display: "flex",
          width: directionProps.width
        }}
      >
        <div
          ref={iRenderTrackParams.props.ref}
          style={{
            height: directionProps.innerHeight,
            width: directionProps.width,
            borderRadius: "4px",
            background: getTrackBackground({
              values: [value * 100],
              colors: ["#E74E25", "#ccc"],
              min: 0,
              max: 100,
              direction: directionProps.direction,
            }),
            alignSelf: "center"
          }}
        >
          {iRenderTrackParams.children}
        </div>
      </div>
    )
  }

  const renderThumb = ({ props }: IRenderThumbParams) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: "13px",
        width: "13px",
        borderRadius: "50px",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 6px #AAA"
      }}
    >
    </div>
  )

  function getTrackName(value: EpisodesAndEvaluations) {
    let name = '';
    switch (value.type) {
      case ShowingType.episode:
        const ep = value as Episodes;
        name = ep.episode_name;
        break;
      case ShowingType.courseEvaluation:
        name = 'Post Course Evaluation';
        break;
    }
    if (value.isAssessment) {
      name = `Quiz For Episode ${value.episode_number - 1}`;
    }
    return name;
  }

  const handleClickPreview = () => {
    handlePlay();
  }

  function skipOrRewind(type: string) {
    if (!videoState.duration) {
      return;
    }
    const nextPercentage = 10 / videoState.duration;
    const finalPercentage = type === 'skip'
                            ? videoState.played + nextPercentage
                            : videoState.played - nextPercentage;
    if (type === 'skip') {
      (finalPercentage < 1) && handleSeekChange(finalPercentage);
      return;
    }
    (finalPercentage > 0) && handleSeekChange(finalPercentage);
  }

  function getOnProgress() {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      const currentTime = videoElement.currentTime;
      const duration = videoElement.duration;
      const playedPercentage = currentTime/duration * 100;

      handleDuration(duration);
      localStorage.setItem('episodeCompletionPercentage', (playedPercentage).toFixed(2));
      localStorage.setItem('lastSecond', currentTime.toFixed(2).toString())
    }
  }

  return (
    <div className="player-wrapper video-player"
      onMouseMove={() => { handleVisible(setControllerVisible) }}
      onMouseLeave={() => { handleHidden(setControllerVisible) }}
      ref={playerContainerRef}>
      {
        !forceNativeController() || ( forceNativeController() && detectAndroid())
        ?
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            ref={player}
            url={videoState.url}
            pip={videoState.pip}
            light={false}
            playing={videoState.playing}
            controls={videoState.controls}
            loop={videoState.loop}
            playbackRate={videoState.playbackRate}
            volume={videoState.volume}
            muted={videoState.muted}
            onClickPreview={handleClickPreview}
            onReady={handleReady}
            onStart={handleStart}
            onPlay={handlePlay}
            onEnablePIP={handleEnablePIP}
            onDisablePIP={handleDisablePIP}
            onPause={handlePause}
            onEnded={handleEnded}
            onSeek={handleOnSeek}
            onProgress={handleProgress}
            onDuration={handleDuration}
            config={{
              file: {
                attributes: {
                  poster: props?.video_thumbnail?.url || `https://videodelivery.net/${props.video_id}/thumbnails/thumbnail.jpg`,
                }
              }
            }}
          />
        :
          <video id='video'
                 width="100%"
                 height="100%"
                 className="react-player"
                 loop={videoState.loop}
                 muted={videoState.muted}
                 autoPlay={videoState.playing}
                 controls={videoState.controls}
                 onLoadStart={handleReady}
                 onTimeUpdate={getOnProgress}
                 onPlay={handlePlay}
                 onPause={handlePause}
                 onSeeked={handleOnSeek}
                 onEnded={handleEnded}>
            <source src={videoState.url} />
          </video>
      }
      {(videoContinue && continueVisible && !props.isShowDowloadCert) && (
        <div className={`continue-wrapper`}>
          <div className="continue-block">
            <div className="child-block">
              <h5 className="color-white sm-f-s-14 ipad-f-s-16 sm-m-0">
                {getTrackName(videoContinue.episodeOrQuiz)}
              </h5>
              <p className="text-center sm-m-b-5 ipad-m-b-5">
                เริ่มบทเรียนใน
                <span className="color-primary">
                  &nbsp;
                  <Countdown second={5} callback={handleContinue} /> วินาที
                </span>
              </p>
            </div>
            <div className="child-block image-block">
              <Img
                src={"thumbnail_image" in videoContinue?.episodeOrQuiz ? videoContinue?.episodeOrQuiz?.thumbnail_image : ''}
                width={460}
                height={220} />
            </div>
            <div className="child-block button-block">
              <button
                className="btn btn-box sm-f-s-12 btn-small m-t-0 m-r-0  lg-m-b-10"
                onClick={() => videoContinue.callBackContinue()}>
                เริ่มเรียนทันที
              </button>
              <br />
              <button
                className='btn-link m-0'
                onClick={() => setContinueVisible(false)}>
                ออกจากบทเรียน
              </button>
            </div>
          </div>
        </div>
      )}
      {(!onStarted && !forceNativeController()) && (
        <div className={`controls-wrapper column-center`} onClick={handlePlay}>
          <Img
            src="/videoPlayer/play-solid.svg"
            width={50}
            height={50}
            className="filter-white" />
        </div>
      )}
      {(controllerVisible && (videoContinue ? !continueVisible : true) && !videoState.controls && onStarted) && (
        <div className="controls-wrapper">
        <div className="p-h-100" onClick={handlePlayPause}>
        </div>
        <div className={`video-controller ${(videoState.controls) && "hidden"}`}>
          <div className="flex-column-center">
            <div className="flex-column-center control-button" onClick={handlePlayPause}>
              {videoState.playing ? (
                <Img src="/videoPlayer/pause-solid.svg"
                  width={20}
                  height={20}
                  className="filter-white" />) : (
                <Img src="/videoPlayer/play-solid.svg"
                  width={20}
                  height={20}
                  className="filter-white" />)}
            </div>
          </div>
          <div className="d-flex align-items-center f-s-12 sm-f-s-8 color-white">
            <span className='text-white'>{ getMinuteAndSecond(videoState.playedSeconds) }</span> /
            <span className='text-white'>{ getMinuteAndSecond(videoState.duration) }</span>
          </div>
          <div className="flex-column-center">
            <div className={`control-button pointer`}
                 onClick={() => skipOrRewind('rewind')}>
              <Img src="/videoPlayer/rewind-ten-sec.svg"
                   width={26}
                   height={26}
                   className="filter-white" />
            </div>
          </div>
          <div className="flex-column-center">
            <div className={`control-button pointer`}
                 onClick={() => skipOrRewind('skip')}>
              <Img src="/videoPlayer/skip-ten-sec.svg"
                   width={26}
                   height={26}
                   className="filter-white" />
            </div>
          </div>
          <div className="video-progress">
            <Range
              values={[videoState.played]}
              step={0.00001}
              min={0}
              max={1}
              onChange={(values) => { handleSeekChange(values[0]) }}
              renderTrack={(params) => { return renderTrack({ iRenderTrackParams: params, value: videoState.played, directions: Directions.Horizontal }) }}
              renderThumb={renderThumb}
            />
          </div>
          <div className="control-right-group">
            <div className="flex-column-center"
              onMouseEnter={() => {
                handleVisible(setVolumeVisible);
                setResolutionsVisible(false);
                setPlaybackVisible(false);
              }}
              onMouseLeave={() => {
                handleHidden(setVolumeVisible);
                setResolutionsVisible(false);
                setPlaybackVisible(false);
              }}>
              <div className={`volume-progress flex-column-center ${volumeVisible ? "visible" : "hidden"}`}>
                <Range
                  values={[videoState.volume]}
                  step={0.00001}
                  min={0}
                  max={1}
                  onChange={(values) => { handleVolumeChange(values[0]) }}
                  renderTrack={(params) => {
                    return renderTrack({ iRenderTrackParams: params, value: videoState.volume, directions: Directions.Vertical })
                  }}
                  renderThumb={renderThumb}
                  direction={Direction.Up}
                />
              </div>
              <div className="flex-column-center control-button" onClick={handleToggleMuted}>
                {!videoState.muted ? (
                  <Img src="/videoPlayer/volume-solid.svg"
                    width={20}
                    height={20}
                    className="filter-white" />
                ) : (
                  <Img src="/videoPlayer/volume-xmark-solid.svg"
                    width={20}
                    height={20}
                    className="filter-white" />
                )}
              </div>
            </div>
            {
              hasSubtitle &&
                <div className="flex-column-center">
                  <div className={`control-button pointer subtitle`}
                       onClick={toggleSubtitle}>
                    <Img src={ !showSubtitle ? '/videoPlayer/caption.svg' : '/videoPlayer/caption-off.svg'}
                         width={26}
                         height={26}/>
                  </div>
                </div>
            }
            <div className="flex-column-center"
              onClick={() => {
                handleSwitchVisible(resolutionsVisible, setResolutionsVisible);
                setVolumeVisible(false);
                setPlaybackVisible(false);
              }}>
              <div className={`options-item ${resolutionsVisible ? "visible" : "hidden"}`}>
                <div className="option-select">
                  <button className={`option-select ${-1 === videoState.currentResolution && "options-active"}`}
                    onClick={() => setVideoResolution(-1)}>Auto</button>
                  {videoState.resolutions.map((item: any, index: number) => {
                    return <button className={`option-select f-s-14 ${index === videoState.currentResolution && "options-active"}`}
                      key={index}
                      onClick={() => setVideoResolution(index)}>{item.height}p</button>
                  })}
                </div>
              </div>
              <div className="control-button flex-column-center">
                <Img src="/videoPlayer/gear-solid.svg"
                  width={20}
                  height={20}
                  className="filter-white" />
              </div>
            </div>
            <div className="flex-column-center"
              onClick={() => {
                handleSwitchVisible(playbackVisible, setPlaybackVisible);
                setResolutionsVisible(false);
                setVolumeVisible(false);
              }}>
              <div className={`options-item ${playbackVisible ? "visible" : "hidden"}`}>
                <div className="option-select">
                  {playbackOptions.map((item: number, index: number) => {
                    return <button className={`option-select sm-f-s-10 ${item === videoState.playbackRate && "options-active"}`}
                      key={index}
                      onClick={() => handleSetPlaybackRate(item)}>{item}x</button>
                  })}
                </div>
              </div>
              <div className="control-button flex-column-center sm-f-s-10 cursor-default lg-w-50 color-white">
                {videoState.playbackRate}x
              </div>
            </div>
            <div className="flex-column-center">
              <div className="video-full-screen control-button" onClick={handleClickFullscreen}>
                <Img src="/videoPlayer/expand-wide-solid.svg"
                  width={20}
                  height={20}
                  className="filter-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}
 
export default VideoPlayer;
